import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';
import { reportTranslation } from '../../logic/api';
import { getLanguage, getLanguages, getLanguagesLabelValue, toFormatedDateString } from '../../logic/util';
import noChatSelected from '../../resources/no-chat-selected.png';
import { archiveChat, assignChat, assignChatInit, blockChat, openChat, sendMessage, updateChatLanguages } from '../../state/activeChat';
import { addErrorToast, addInfoToast } from '../base/ToastManager';
import { CustomPlainToggle } from '../base/minor/CustomDropdown';
import { SearchDropdownWithLabelPrefix, SearchDropdownWithQuickPicks } from '../base/minor/SearchDropdown';
import MediaModal from '../modals/MediaModal';
import { addModal } from '../modals/ModalManager';
import { QRManage } from '../modals/QuickReplyManagement';
import './ChatBox.scss';
import ChatDetails from './ChatDetails';
import ChatEmailMessage from './ChatEmailMessage';
import { ChatFooterWrap } from './ChatInputFooter';
import { Separator, ThinButton } from './minor/Common';
import EllipsisLoading from './minor/EllipsisLoading';

export default function ChatBox(params) {
    const dispatch = useDispatch();
    const { t } = useTranslation("chat")
    const activeChat = useSelector(state => state.activeChat)
    const user = useSelector(state => state.user)
    let messagesEnd = useRef(null);
    const prevDetails = useRef(0);
    const scrollToMsg = useRef(null);
    const lastMsgRef = useRef(null);

    function sendInputs(data) {
        dispatch(sendMessage(activeChat.activeContactID, activeChat.contact.sourceType, data.main, data.tranlsation))
    }

    function selfAssignChat(lang) {
        dispatch(assignChatInit(activeChat.activeContactID, lang, activeChat.contact.visitorLang))
    }

    function assignChatTo(userID) {
        dispatch(assignChat(activeChat.activeContactID, parseInt(userID)))
    }

    function askToBlock() {
        dispatch(blockChat(activeChat.activeContactID, activeChat.contact?.isBlocked))
    }

    function askArchiveChat() {
        dispatch(archiveChat(activeChat.activeContactID))
    }

    function reopenChat() {
        dispatch(openChat(activeChat.activeContactID))
    }

    function changeVisiorLanguage(newLang) {
        let langFull = getLanguage(newLang, "eng")
        dispatch(updateChatLanguages({
            contactID: activeChat.activeContactID,
            visitorLang: langFull.iso,
        }))
    }

    function changeSupportLanguage(newLang) {
        let langFull = getLanguage(newLang, "eng")
        dispatch(updateChatLanguages({
            contactID: activeChat.activeContactID,
            supportLang: langFull.iso,
        }))
    }


    useEffect(() => {
        if (lastMsgRef.current) {
            lastMsgRef.current.scrollIntoView();
        } else if (activeChat.scrollToMessageID && (activeChat.scrollToMessageID !== prevDetails.current?.scrollToMessageID)) {

            scrollToMsg.current?.scrollIntoView();
        } else if (activeChat.messages.length !== prevDetails.current?.len) {
            if (messagesEnd.scrollIntoView)
                messagesEnd.scrollIntoView();
        }

        prevDetails.current = {
            len: activeChat.messages.length,
            scrollToMessageID: activeChat.scrollToMessageID,
        };
    }, [activeChat]);

    let users = user?.siteDetails.siteUsers?.map(siteUser => siteUser.user) || []
    let assignedUser = users.find(u => u.id === activeChat.contact.currentAssigneeID)

    let visitor = activeChat.contact;
    let visitorLang = getLanguage(activeChat.contact.visitorLang)
    let supportLang = getLanguage(activeChat.contact.supportLang)

    let lastSeenVisitorTime = visitor.visitorLastSeenMessageTime ? new Date(visitor.visitorLastSeenMessageTime) : null;
    let lastMessageDate;

    let messages = activeChat.messages.map((el, i) => {
        if (el.messageType === "EMAIL")
            return <ChatEmailMessage key={el.id} data={el} users={users} ref={(activeChat.messages?.length - 1 === i) ? lastMsgRef : null} defaultOpened={(activeChat.messages?.length - 1 === i)} />

        let d1 = new Date(el.createdAt)
        let isSameDay = lastMessageDate && (d1.getFullYear() === lastMessageDate.getFullYear() &&
            d1.getMonth() === lastMessageDate.getMonth() &&
            d1.getDate() === lastMessageDate.getDate());
        lastMessageDate = d1

        let refProp = {}
        if (el.id === activeChat.scrollToMessageID) {
            refProp = { ref: scrollToMsg }
        }
        return <ChatBubble key={el.id} {...refProp} data={el} supportLang={supportLang} users={users} includeDatePrefix={!isSameDay} opposite={true} lastSeenVisitorTime={lastSeenVisitorTime} />
    })
    const { currentAssigneeID, status } = activeChat.contact
    let archived = status === "done"
    let assignedToAssistant = status === "askly_assistant"
    let showLanguages = activeChat.contact.sourceType !== "EMAIL"
    let loading = activeChat.loading
    let haveActiveChat = activeChat.activeContactID !== 0;
    let isAssignedToMe = assignedUser && (assignedUser.id === user.userDetails.id)
    let isWaitingAssignment = haveActiveChat && (!currentAssigneeID || isAssignedToMe) && (!status || status === "pending")

    return (
        <div className="chat-box">
            <div className="assignee">
                {assignedToAssistant && !archived && <div className='d-flex mr-2'>
                    <SearchDropdownWithLabelPrefix className="mb-0" label={t("leading the chat")} useLabelAsSuffix menuWidth={220} currentSelect={t("Askly AI Assistant")} allSelection={users} valueKey="id" labelKey="name" secondLabelKey="email" onSelect={assignChatTo} filterCurrentlySelected={true} />
                    {!archived && !isAssignedToMe && <ThinButton className="ml-3 flex-shrink-0" onClick={() => { assignChatTo(user.userDetails.id) }}>{t("Take over the chat")}</ThinButton>}
                </div>}
                {currentAssigneeID > 0 && !archived && <div className='d-flex mr-2'>
                    <SearchDropdownWithLabelPrefix className="mb-0" label={t("leading the chat")} useLabelAsSuffix menuWidth={220} currentSelect={assignedUser?.name || t("Unknown")} allSelection={users} valueKey="id" labelKey="name" secondLabelKey="email" onSelect={assignChatTo} filterCurrentlySelected={true} />
                    {!archived && !isAssignedToMe && <ThinButton className="ml-3 flex-shrink-0" onClick={() => { assignChatTo(user.userDetails.id) }}>{t("Take over the chat")}</ThinButton>}
                </div>}
                {!assignedToAssistant && !currentAssigneeID && !archived && <div className='d-flex'>
                    <label>{t("New chat")}</label>
                </div>}
                {archived && <div className='d-flex'>
                    <label>{t("Chat archived")}</label>
                </div>}
                <div className='ml-auto'></div>
                {showLanguages && <div className=' mr-2 d-flex'>
                    <SearchDropdownWithLabelPrefix className="mr-3 mb-0 limit-len" menuWidth={220} label={t("Customer")} disabled={!isAssignedToMe} currentSelect={visitorLang.eng} allSelection={getLanguages()} onSelect={changeVisiorLanguage} />
                    <SearchDropdownWithLabelPrefix className="mb-0 limit-len" menuWidth={220} label={t("Support")} disabled={!isAssignedToMe} currentSelect={supportLang.eng} allSelection={getLanguages()} onSelect={changeSupportLanguage} />
                </div>}
                {!archived && isAssignedToMe && <ThinButton className="flex-shrink-0" onClick={askArchiveChat}><i className="mx-1 far fa-check"></i>{t("Archive")}</ThinButton>}
            </div>
            <div className="chat-content-wrap">
                <div className='chat-scroll-wrap'>
                    <div className="chat-scroll">
                        <div className="chat-action">{t("Chat started")}</div>
                        {!loading && messages}
                        <div ref={(el) => { messagesEnd = el; }}></div>
                    </div>
                    <IsWriting isVisitorWriting={activeChat?.isVisitorWriting} />
                    <ChatFooterWrap
                        loading={loading}
                        activeChat={activeChat}
                        selfAssignChat={selfAssignChat}
                        user={user}
                        sendInputs={sendInputs}
                        status={status}
                        archived={archived}
                        isAssignedToMe={isAssignedToMe}
                        isWaitingAssignment={isWaitingAssignment}
                        askArchiveChat={askArchiveChat}
                        askToBlock={askToBlock}
                        changeVisiorLanguage={changeVisiorLanguage}
                        visitorLang={visitorLang}
                        supportLang={supportLang}
                        changeSupportLanguage={changeSupportLanguage}
                        reopenChat={reopenChat}
                        isBlocked={activeChat.contact?.isBlocked}
                        assignChatTo={assignChatTo}
                        onClick={() => { assignChatTo(user.userDetails.id) }}
                        assignedUser={assignedUser}
                    />
                </div>
                <ChatDetails />
            </div>
        </div>
    )
}

function IsWriting({ isVisitorWriting }) {
    const { t } = useTranslation("chat")
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(isVisitorWriting)

        if (isVisitorWriting) {
            const timer = setTimeout(() => {
                setShow(false)
            }, 12000);

            return () => clearTimeout(timer);
        }
    }, [isVisitorWriting])

    if (show)
        return <div className='is-writing'>{t("Visitor writing")}<EllipsisLoading className="" /></div>


    return null
}


const ChatBubble = forwardRef((params, ref) => {
    const [optionsToggled, setOptionsToggled] = useState(false);
    const { t } = useTranslation("chat")
    function report() {
        setOptionsToggled(false);
        reportTranslation({ messageID: params.data.id }).then(() => {
            addInfoToast(t("Translation reported"))
        }).catch(() => {
            addErrorToast(t("Translation report failed"))
        })
    }

    function getUserNameByID(id) {
        if (id === 0)
            return t("Askly Assistant")

        return params?.users.find(u => u.id === id)?.name || t("Unknown")
    }

    let isSeen = params.data.isSupportResponse && (new Date(params.data.createdAt) <= params.lastSeenVisitorTime)
    let seenAtTime = toFormatedDateString(params.data.createdAt, false, true, true)
    let seenAtTxt = isSeen ? t("Seen ✓ {{seenAt}}", { seenAt: seenAtTime }) : seenAtTime

    if (params.data.isDeleted) {
        return (
            <div ref={ref} className="chat-bubble-container">
                <div className={params.data.isSupportResponse ? "chat-bubble opposite" : "chat-bubble"}>
                    <div className="chat-content" >
                        <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                        <span className='deleted-message' style={{ color: "#a0acc6" }}>{t("Message deleted")}</span>
                        <span className="timestamp">{seenAtTxt}</span>
                    </div>
                </div>
            </div>
        )
    }

    if (params.data.messageType === "SUPPORT:NOTE") {
        let note = params.data?.attachments?.find(el => el.attachmentType === "NOTE")

        return (
            <div ref={ref} className={"chat-input-bubble chat-bubble opposite"}>
                <div className='note-bubble-container'>
                    <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                    <span><TWLinkify>{note?.noteText}</TWLinkify></span>
                    <span className="timestamp">{t("Private note")} | {seenAtTime}</span>
                </div>
            </div>
        )
    }

    let interactiveObject = null;
    if (params.data.messageType === "INPUT:EMAIL") {
        let response = params.data?.attachments?.find(el => el.attachmentType === "INPUT:RESPONSE")
        interactiveObject = (
            <div className={"chat-input-bubble chat-bubble " + (params.data.isSupportResponse ? "opposite" : "")}>
                <div className="chat-input">
                    <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                    <div className="response" >{response?.inputResponse || t("Sent email request")}</div>
                    <span className="timestamp">{seenAtTxt}</span>
                </div>
            </div>)
    } else if (params.data.messageType === "INPUT:PHONE_NR") {
        let response = params.data?.attachments?.find(el => el.attachmentType === "INPUT:RESPONSE")
        interactiveObject = (
            <div className={"chat-input-bubble chat-bubble " + (params.data.isSupportResponse ? "opposite" : "")}>
                <div className="chat-input">
                    <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                    <div className="response" >{response?.inputResponse || t("Sent phone number request")}</div>
                    <span className="timestamp">{seenAtTxt}</span>
                </div>
            </div>)
    }

    let translationAttachment = null;
    let foundTranslations = params?.data?.attachments?.filter(el => (el.attachmentType === "TRANSLATION"))
    if (foundTranslations?.length > 1) {
        translationAttachment = foundTranslations.find(el => (el.translationLanguage === params.supportLang?.iso))
        if (!translationAttachment)
            translationAttachment = foundTranslations[0]

    } else if (foundTranslations?.length === 1) {
        translationAttachment = foundTranslations[0]
    }

    let fileAttachmentsFiltered = params.data.attachments && params.data.attachments.filter(el => el.attachmentType === "FILE")
    let fileAttachments = null;
    if (fileAttachmentsFiltered) {
        fileAttachments = fileAttachmentsFiltered.map(file => {
            let isImage = file.mimeType?.includes("image") || (file.isExternalFile && file.externalType === "image")

            if (isImage) {
                let openImage = function () {
                    addModal(<MediaModal />, {
                        data: {
                            mediaURL: (file.isExternalFile ? file.url : ("/api" + file.url)),
                            mimeType: file.mimeType,
                        }
                    })
                }

                return (
                    <div key={file.url} onClick={openImage} className={"image-bubble chat-bubble" + (params.data.isSupportResponse ? " opposite" : "")} >
                        <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                        <img alt="Attachment" src={file.isExternalFile ? file.url : ("/api" + file.url)} />
                        <span className="timestamp">{seenAtTxt}</span>
                    </div>)
            }

            return <a key={file.url} target="_blank" rel="noreferrer" className={params.data.isSupportResponse ? "chat-bubble opposite" : "chat-bubble"} href={file.isExternalFile ? file.url : ("/api" + file.url)} download>
                <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                <i className="file-ico far fa-file-alt"></i>
                <span className="ellipsis">{file.fileName}</span>
                <i className="download far fa-arrow-to-bottom"></i>
                <span className="timestamp">{seenAtTxt}</span>
            </a>
        })
    }

    let instaStory = params.data?.attachments?.find(el => el.attachmentType === "INSTA_STORY_MENTION")
    if (instaStory) {
        let openStory = function () {
            addModal(<MediaModal />, {
                data: {
                    instaStory: instaStory,
                    mediaURL: instaStory.url,
                    mimeType: instaStory.mimeType,
                }
            })
        }

        if (instaStory.mimeType?.includes("video")) {
            fileAttachments = [(
                <div key={instaStory.url} onClick={openStory} className={"image-bubble chat-bubble" + (params.data.isSupportResponse ? " opposite" : "")} >
                    <video controls={false} preload="metadata" src={instaStory.url} onError={e => { e.target.poster = "/images/icon/broke-img.svg" }} />
                    <span className="timestamp">{seenAtTxt}</span>
                </div>)]
        } else {
            fileAttachments = [(
                <div key={instaStory.url} onClick={openStory} className={"image-bubble chat-bubble" + (params.data.isSupportResponse ? " opposite" : "")} >
                    <img alt='attachment' height={84} width={84} src={instaStory.url} onError={e => { e.target.src = "/images/icon/broke-img.svg" }} />
                    <span className="timestamp">{seenAtTxt}</span>
                </div>)]
        }


    }

    let replyTo;
    let replyToAttachment = params.data?.attachments?.find(el => el.attachmentType === "MESSAGE_REPLY:INSTA_STORY")
    if (replyToAttachment) {
        let openStory = function () {
            addModal(<MediaModal />, {
                data: {
                    instaStory: replyToAttachment,
                    mediaURL: replyToAttachment.url,
                    mimeType: replyToAttachment.mimeType,
                }
            })
        }

        if (replyToAttachment.mimeType?.includes("video")) {
            replyTo = (
                <div key={replyToAttachment.url} className="reply-wrap">
                    <div className='reply-txt'>{t("Reply to")}</div>
                    <div onClick={openStory} className={"image-bubble chat-bubble" + (params.data.isSupportResponse ? " opposite" : "")} >
                        <video controls={false} preload="metadata" src={replyToAttachment.url} onError={e => { e.target.poster = "/images/icon/broke-img.svg" }} />
                    </div>
                </div>)
        } else {
            replyTo = (
                <div key={replyToAttachment.url} className="reply-wrap">
                    <div className='reply-txt'>{t("Reply to")}</div>
                    <div onClick={openStory} className={"image-bubble chat-bubble" + (params.data.isSupportResponse ? " opposite" : "")} >
                        <img alt='attachment' height={84} width={84} src={replyToAttachment.url} onError={e => { e.target.src = "/images/icon/broke-img.svg" }} />
                    </div>
                </div>)
        }
    }

    function addQuickReply() {
        if (params.data?.isSupportResponse && params.data?.messageContent) {
            addModal(<QRManage />, {
                data: {
                    openNew: true,
                    suggestedReply: true,
                    suggestedText: params?.data?.messageContent,
                    suggestedLang: params?.supportLang?.iso,

                }
            })
        }
    }

    return (
        <div ref={ref} className="chat-bubble-container">
            {replyTo}
            {params.includeDatePrefix && <div className="chat-action">{toFormatedDateString(params.data.createdAt, true, false, false)}</div>}
            {params.data.messageContent &&
                <div className={params.data.isSupportResponse ? "chat-bubble opposite" : "chat-bubble"}>
                    <div className="chat-content" >
                        <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
                        <span><TWLinkify>{params.data.messageContent}</TWLinkify></span>
                        <span className="timestamp">{seenAtTxt}</span>
                    </div>
                    {(translationAttachment) &&
                        <div className="chat-attachment">
                            <div className="chat-translation-attachment"><TWLinkify>{translationAttachment.translationText}</TWLinkify></div>
                        </div>}

                    <div className={"chat-options " + (optionsToggled ? "open" : "")}>
                        {translationAttachment &&
                            <Dropdown className="m-auto" onToggle={isOpen => { setOptionsToggled(isOpen) }}>
                                <Dropdown.Toggle className="m-auto options" as={CustomPlainToggle}>
                                    <i className="fas fa-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align={params.data.isSupportResponse ? "right" : "left"}>
                                    <Dropdown.Item className="option-item" onSelect={report}>{t("Report translation")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        {params.data.isSupportResponse && <div onClick={addQuickReply} className='quick-reply-shortcut'><i className="fa-bookmark far"></i></div>}
                    </div>
                </div>}
            {fileAttachments}
            {interactiveObject}
        </div >
    )
})

function TWLinkify(params) {
    return (<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
        </a>
    )}>{params.children}</Linkify>)
}




function ChatAcceptFooter(params) {
    const userDetails = useSelector(state => state.user.userDetails)
    const [selectedLang, setSelectedLang] = useState(getInitalSelectedLang())
    const { t } = useTranslation("chat")
    const fallbackLanguages = ["en", "et", "ru", "fi"]
    let foundLangs = [];
    if (userDetails.languages && userDetails.languages.length > 0) {
        foundLangs = userDetails.languages
    } else {
        foundLangs.push(params.visitorLang.iso, ...fallbackLanguages)
    }

    foundLangs = [...new Set(foundLangs)]; // TODO BETTER
    let quickPicks = foundLangs.map(el => ({ label: getLanguage(el).eng, value: el }))

    useEffect(() => {
        setSelectedLang(getInitalSelectedLang())
    }, [params.visitorLang])

    function getInitalSelectedLang() {
        if (userDetails.languages && userDetails.languages.length > 0) {
            let foundLang = userDetails.languages.find(l => params.visitorLang.iso === l)
            if (foundLang) {
                return foundLang
            }

            if (userDetails.languages[0])
                return userDetails.languages[0]
        }

        return "en"
    }

    return (
        <div className='start-conversation-wrap'>
            <Separator>
                {t("Choose the language and reply")}
            </Separator>
            <div className='extra-actions'>
                <SearchDropdownWithLabelPrefix leftAlign topAlign className="mb-0" menuWidth={220} label={t("Visitor")} currentSelect={params.visitorLang.eng} allSelection={getLanguages()} onSelect={params.changeVisiorLanguage} />
                <SearchDropdownWithQuickPicks
                    topAlign prefixRight
                    className="ml-auto mb-0"
                    label={t("Me")}
                    menuWidth={220}
                    valueKey="value"
                    labelKey="label"
                    quickPicks={quickPicks}
                    currentValue={selectedLang}
                    currentSelect={t("Other")}
                    allSelection={getLanguagesLabelValue()}
                    onSelect={setSelectedLang} />
            </div>
            <div className='language-select-wrap'>
                <div style={{ minWidth: 1, flex: 1 }}></div>
                <div className="d-flex" style={{ minWidth: 1, flex: 1 }}>
                    <div style={{ position: 'relative', cursor: 'pointer', margin: 'auto', color: "#fff", background: "#9686f2", padding: "6px 16px", borderRadius: 6 }} onClick={() => { params.onClick(selectedLang) }}>
                        <div style={{
                            position: "absolute",
                            color: "rgb(136, 152, 183)",
                            fontSize: 12,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            transform: "translate(-50%, 0px)",
                            inset: "auto auto calc(100% + 2px) 50%",
                        }}>{params.visitorLang.eng} to {getLanguage(selectedLang, "iso").eng}</div>
                        {t("Take over the chat")}
                    </div>
                </div>
                <div className="d-flex" style={{ minWidth: 1, flex: 1 }}>
                    <ThinButton type="secondary" className="ml-auto" onClick={params.archive}><i className="mx-1 far fa-check"></i>{t("Archive")}</ThinButton>
                    <ThinButton type="secondary" className="ml-2" onClick={params.askToBlock}><i className="mx-1 far fa-ban"></i>{t("Block")}</ThinButton>
                </div>
            </div>
        </div>
    )
}


function ArchivedFooter(params) {
    const { t } = useTranslation("chat")

    return (
        <div className='start-conversation-wrap'>
            <Separator>
                {!params.isBlocked && <span> {t("Chat has been archived")}</span>}
                {params.isBlocked && <span> {t("Chat has been blocked")}</span>}
            </Separator>
            <div className='extra-actions mb-0'>
                {!params.isBlocked && <ThinButton type="secondary" className="mx-auto" onClick={params.reopenChat}>{t("Reopen chat")}</ThinButton>}
            </div>
        </div >
    )
}

function NotAssignedFooter(params) {
    const { t } = useTranslation("chat")

    return (
        <div className='start-conversation-wrap'>
            <Separator>
                <span>
                    <span>{t("Chat is assigned to {{name}}", { name: params.assignedUser?.name })}</span><br></br>
                    <span>
                        <Trans
                            t={t}
                            defaults="Press <i>'Take over the chat'</i> to take control of the chat"
                        />
                    </span>
                </span>
            </Separator>
            <div className='extra-actions mb-0'>
                <SearchDropdownWithLabelPrefix topAlign disabled className="mb-0" menuWidth={220} label={t("Customer language")} currentSelect={params.visitorLang.eng} allSelection={getLanguages()} onSelect={() => { }} />
                <ThinButton type="secondary" className="ml-auto" onClick={params.onClick}>{t("Take over the chat")}</ThinButton>
            </div>
        </div >
    )
}



export function NoActiveChatPH() {
    const { t } = useTranslation("chat")

    return (
        <div className='chat-ph d-flex'>
            <figure className="no-select mx-auto">
                <img className="default" src={noChatSelected} alt="No Chat active" />
                <figcaption className="caption">
                    <Trans t={t}
                        defaults='<title>It takes weeks to get customers,<br /> and seconds to lose.</title><p>Take good care of them.</p>'
                        components={{ title: <span className="small-title"></span> }}
                    />
                </figcaption>
            </figure>
        </div >
    )
}

