
import i18next from 'i18next';
import { addErrorToast } from '../components/base/ToastManager';
import { fetchChats } from './../logic/api';

const initialState = {
    loading: false,
    inboxCount: 0,
    meCount: 0,
    othersCount: 0,
    activeFilter: "inbox",
    termFilter: "",
    sourceFilter: "all",
    tagFilter: [],
    chats: [],
    hasMore: false,
    error: null,
}

let abortListController;
const loadList = filter => ({ type: "CHAT_LIST:START_FETCH", payload: filter });
export const failFetchChatList = error => ({ type: "CHAT_LIST:FAIL_FETCH", payload: error });
export const finishFetchChatList = data => ({ type: "CHAT_LIST:FINISH_FETCH", payload: data });
export const finishFetchNextChatList = data => ({ type: "CHAT_LIST:FINISH_NEXT_FETCH", payload: data });

// We send message to WS and add it chat. Upon ws accept this msg will be marked as sent (TODO)
export const fetchChatList = (filter = {}) => (dispatch, getState) => {
    if (!filter.activeFilter)
        filter.activeFilter = "inbox"

    if (!filter.sourceFilter)
        filter.sourceFilter = "all"

    if (!filter.tagFilter)
        filter.tagFilter = []

    if (!filter.termFilter)
        filter.termFilter = ""

    if (abortListController?.signal?.aborted === false) {
        abortListController.abort();
    }

    abortListController = new AbortController();
    dispatch(loadList(filter))
    fetchChats(filter, abortListController.signal).then(data => {
        dispatch(finishFetchChatList(data))
    }).catch(e => {
        if (e.name === 'AbortError') {
            return; // Aborting request will return an error
        }

        addErrorToast(i18next.t("Could not fetch chat list"))
    })
}

export const fetchNextChatList = () => (dispatch, getState) => {
    let chatList = getState().chatList
    if (chatList.hasMore) {
        let lastElem = chatList.chats.slice(-1)?.[0]
        let after = new Date(lastElem.messageTime).getTime()

        let filter = {
            termFilter: chatList.termFilter,
            sourceFilter: chatList.sourceFilter,
            tagFilter: chatList.tagFilter,
            activeFilter: chatList.activeFilter,
            after: after,
        }

        fetchChats(filter).then(data => {
            dispatch(finishFetchNextChatList(data))
        }).catch(e => {
            addErrorToast(i18next.t("Could not fetch chat list"))
        })
    }
}

const reducer = (state = initialState, action) => {
    let changedFields = {}

    var foundChat;
    switch (action.type) {
        case "SITE:CHANGE_SITE":
            return { ...initialState };
        case "CHAT_LIST:SEARCH_TERM":
            return { ...state, termFilter: action.payload };
        case "CHAT_LIST:START_FETCH":
            return { ...state, chats: [], hasMore: false, loading: true, ...action.payload };
        case "CHAT_LIST:FINISH_NEXT_FETCH":
            return { ...state, loading: false, hasMore: action.payload.hasMore, error: null, chats: [...state.chats, ...action.payload?.chats] };
        case "CHAT_LIST:FINISH_FETCH":
            return { ...state, ...action.payload, loading: false, error: null };
        case "CHAT_LIST:FAIL_FETCH":
            return { ...state, loading: false, error: action.payload };
        case "CHAT:ONLINE":
        case "CHAT:OFFLINE":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, isConnected: (action.type === "CHAT:ONLINE") }
                            : chat
                    ),
                };
            }
            return state; // Same object does not trigger reload
        case "CHAT:ADD_TAG":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, tags: [...chat.tags || [], action.payload.tagging] }
                            : chat
                    ),
                };
            }

            return state; // Same object does not trigger reload
        case "CHAT:REMOVE_TAG":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, tags: ([...chat.tags || []]).filter((tagging) => tagging.tag.id !== action.payload.tagId) }
                            : chat
                    ),
                };
            }

            return state; // Same object does not trigger reload
        case "CHAT:WRITING_START":
        case "CHAT:WRITING_STOP":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, isVisitorWriting: (action.type === "CHAT:WRITING_START") }
                            : chat
                    ),
                };
            }

            return state; // Same object does not trigger reload
        case "CHAT:NEW_EXTERNAL_MESSAGE":
            let increaseCount = false
            let isSearchActive = (state.termFilter || state.textFilter || state.termFilter || state.tagFilter?.length) ? true : false
            if (isSearchActive) {
                return state
            }

            if (!action.payload.isSupportResponse) {
                const { hasChanges, list } = contactNotificationModification("new_message", state.allNotifications, action.payload.contactID, action.payload.sourceType, action.payload.status)
                if (hasChanges) {
                    changedFields.allNotifications = list
                    increaseCount = true
                }

                if (action.payload.isAssignedToMe) {
                    if (addContactToListIfNotExists(state.meNotifications, action.payload.contactID)) {
                        changedFields.meNotifications = [...state.meNotifications, action.payload.contactID]
                        increaseCount = true
                    }
                } else if (action.payload.currentAssigneeID === 0) {
                    if (addContactToListIfNotExists(state.inboxNotifications, action.payload.contactID)) {
                        changedFields.inboxNotifications = [...state.inboxNotifications, action.payload.contactID]
                        increaseCount = true
                    }
                }
            }


            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    ...changedFields,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? {
                                ...chat,
                                isVisitorWriting: false,
                                messageContent: action.payload.messageContent,
                                unreadMessages: (!action.payload.isSupportResponse) ? (foundChat.unreadMessages ? foundChat.unreadMessages + 1 : 1) : chat.unreadMessages,
                            }
                            : chat
                    ),
                };
            }

            if (increaseCount)
                return { ...state, ...changedFields, };

            return state; // Same object does not trigger reload
        case "CHAT:SUPPORT_SET_SEEN_AT":
            let hasChange = false

            if (state.meNotifications?.indexOf(action.payload.contactID) > -1) {
                hasChange = true
                changedFields.meNotifications = state.meNotifications.filter(id => id !== action.payload.contactID)
            }

            if (state.inboxNotifications?.indexOf(action.payload.contactID) > -1) {
                hasChange = true
                changedFields.inboxNotifications = state.inboxNotifications?.filter(id => id !== action.payload.contactID)
            }

            const { hasChanges, list } = contactNotificationModification("message_seen", state.allNotifications, action.payload.contactID)
            if (hasChanges) {
                changedFields.allNotifications = list
                hasChange = true
            }

            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    ...changedFields,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, unreadMessages: null }
                            : chat
                    ),
                };
            }

            if (hasChange) {
                return {
                    ...state,
                    ...changedFields,
                };
            }

            return state; // Same object does not trigger reload
        case "CHAT:CHAT_ASSIGNED":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, status: "assigned" }
                            : chat
                    ),
                };
            }
            return state;
        case "VISITOR:NAME":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, name: action.payload.name }
                            : chat
                    ),
                };
            }
            return state;

        case "VISITOR:EMAIL":
            foundChat = state.chats.find(chat => chat.id === action.payload.contactID)
            if (foundChat) {
                return {
                    ...state,
                    chats: state.chats.map(chat =>
                        chat.id === action.payload.contactID
                            ? { ...chat, email: action.payload.email }
                            : chat
                    ),
                };
            }
            return state;
        default:
            return state;
    }
};

export default reducer;

function removeContactFromListIfExists(list, contactID) {
    if (!list)
        return

    const index = list?.indexOf(contactID);
    if (index > -1) {
        return true
    }

    return false
}

function addContactToListIfNotExists(list = [], contactID) {
    if (!list)
        return

    const index = list.indexOf(contactID);
    if (index === -1) {
        return true
    }

    return false
}

function contactNotificationModification(event, list = [], contactID, sourceType, status) {
    if (!list)
        list = []

    if (event === "new_message") {
        const index = list.findIndex((item) => item.id === contactID);
        if (index === -1) {
            return {
                hasChanges: true,
                list: [...list, { id: contactID, source: sourceType, status }]
            }
        }
    } else if ("message_seen") {
        const index = list.findIndex((item) => item.id === contactID);
        if (index > -1) {
            return {
                hasChanges: true,
                list: list.filter(item => item.id !== contactID)
            }
        }
    }

    return {
        hasChanges: false,
        list
    }
}